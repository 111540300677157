<template>
    <transition name="heightFade">
        <slot/>
    </transition>
</template>

<script>
    export default {
        name: "HeightFadeTransition"
    }
</script>

<style scoped>
    .heightFade-enter-active, .heightFade-leave-active {
        transition: opacity .5s, height .5s;
    }

    .heightFade-enter, .heightFade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        opacity: 0;
        max-height: 0;
    }
</style>